import Icons from './utils/Icons';
import Experience from './Experience';
import ComponentFactory from './ComponentFactory';

class Main {
  constructor() {
    this.init();
  }

  init() {
    document.documentElement.classList.add('has-js');

    Icons.load();

    this.experience = new Experience();

    new ComponentFactory();

    this.handleLoad();
    this.handleURL();
    this.handleAnimationsProjets();
  }

  handleLoad() {
    window.addEventListener('load', () => {
      const loader = document.querySelector('.loader');
      const counter = document.querySelector('.counter');
      const logo = document.querySelector('.logo');

      let startTime = Date.now();
      const updateCounter = () => {
        const elapsed = Date.now() - startTime;
        const loadPercentage = Math.min(
          100,
          Math.floor((elapsed / 1000) * 100)
        );
        counter.textContent = String(loadPercentage).padStart(3, '0');

        if (loadPercentage < 100) {
          requestAnimationFrame(updateCounter);
        } else {
          setTimeout(() => {
            loader.style.opacity = '0';

            logo.style.transform = 'scale(1)';
            logo.style.transition = 'transform 1s ease, z-index 0s 1s';

            setTimeout(() => {
              logo.style.zIndex = '1';

              loader.style.display = 'none';
              document.body.style.overflow = 'auto';
            }, 1000);

            this.experience.startAnimations();
          }, 500);
        }
      };

      updateCounter(); // Start updating the counter
    });
  }

  handleURL() {
    document.addEventListener('click', function (event) {
      const target = event.target.closest('a[href^="#"]');
      if (target) {
        event.preventDefault();
        const targetID = target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetID);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  handleAnimationsProjets() {
    const pageProjets = document.querySelector('.projets');
    const projetsSubTitle = pageProjets.querySelector('.subtitle');
    const textSubTitle = projetsSubTitle.textContent;
    const projets = document.querySelectorAll('.projets .projet__list');

    let typingInterval;

    function typeEffect(text, element, delay = 10) {
      clearInterval(typingInterval);
      element.textContent = '';
      let index = 0;

      typingInterval = setInterval(() => {
        if (index < text.length) {
          element.textContent += text[index];
          index++;
        } else {
          clearInterval(typingInterval);
        }
      }, delay);
    }

    projets.forEach((projet) => {
      projet.addEventListener('mouseenter', function () {
        const subTitle = projet.getAttribute('data-sub-title');
        typeEffect(subTitle, projetsSubTitle);
      });
      projet.addEventListener('mouseleave', function () {
        typeEffect(textSubTitle, projetsSubTitle);
      });
    });
  }
}
new Main();
