import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
  CSS2DRenderer,
  CSS2DObject,
} from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import gsap from 'gsap';

export default class Experience {
  constructor() {
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.mouseX = 0;
    this.mouseY = 0;

    this.canvas = document.querySelector('.webgl');
    this.gltfLoader = new GLTFLoader();
    this.scene = new THREE.Scene();
    this.clock = new THREE.Clock();

    this.pageApropos = document.querySelector('.apropos');
    this.pageProjets = document.querySelector('.projets');
    this.pageContact = document.querySelector('.contact');
    this.pageThousandSunny = document.querySelector('.thousandsunny');

    this.init();
  }

  init() {
    window.addEventListener('mousemove', (event) => {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    });

    this.createCamera();
    this.createObjetcts();
    this.createLights();
    this.createLabels();
    //this.createRaycast();
    this.createRenderer();
    this.createOrbitControls();
    this.animate();

    this.handleClicks();
    this.handleObserve();
  }

  // ===== Camera =====
  createCamera() {
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.sizes.width / this.sizes.height,
      0.1,
      1000
    );

    this.camera.position.set(6, 3, -10);
    this.scene.add(this.camera);
  }

  // ===== Objects =====
  createObjetcts() {
    // Cube 2
    this.cube = new THREE.Mesh(
      new THREE.BoxGeometry(2, 2, 2),
      new THREE.MeshLambertMaterial()
    );
    this.cube.name = 'Cube';
    this.cube.castShadow = true;
    this.cube.receiveShadow = true;

    // Model
    this.gltfLoader.load('assets/models/RamenShop_gltf_03.gltf', (gltf) => {
      this.model = gltf.scene;
      this.model.scale.set(1, 1, 1);
      this.model.position.set(0, 0, 0);

      this.mixer = new THREE.AnimationMixer(this.model);
      const clips = gltf.animations;
      clips.forEach((clip) => {
        const action = this.mixer.clipAction(clip);
        action.setLoop(THREE.LoopRepeat, Infinity);
        action.play();
      });

      this.model.traverse((child) => {
        if (child.isMesh && child.material.isMeshStandardMaterial) {
          child.castShadow = true;
          child.receiveShadow = true;
        }

        if (child.isPointLight) {
          child.visible = true; // Option 1: Make the light invisible
          // Alternatively, you can remove it from the scene
          // scene.remove(child);
        }
      });

      this.scene.add(this.model);
    });

    // Floor
    this.floor = new THREE.Mesh(
      new THREE.BoxGeometry(10, 1, 10),
      new THREE.MeshStandardMaterial({
        color: 0x1b1b1b,
        metalness: 0.2,
        roughness: 0.7,
      })
    );
    this.floor.position.y = -0.58;
    this.floor.name = 'Floor';
    this.floor.castShadow = true;
    this.floor.receiveShadow = true;

    this.scene.add(this.floor);
  }

  // ===== Lights =====
  createLights() {
    const ambiantLight = new THREE.AmbientLight('#868AFF', 1);
    this.scene.add(ambiantLight);

    const directionalLight = new THREE.DirectionalLight('#868AFF', 1);
    directionalLight.position.set(3, 3, 3);
    directionalLight.castShadow = true;

    // Shadow camera properties
    directionalLight.shadow.camera.near = 0.1;
    directionalLight.shadow.camera.far = 10;
    directionalLight.shadow.camera.left = -5;
    directionalLight.shadow.camera.right = 5;
    directionalLight.shadow.camera.top = 5;
    directionalLight.shadow.camera.bottom = -5;

    // Increase shadow map size
    directionalLight.shadow.mapSize.width = 2048;
    directionalLight.shadow.mapSize.height = 2048;

    directionalLight.shadow.normalBias = 0.027;

    this.scene.add(directionalLight);
  }

  // ===== CSS2DRenderer =====
  createLabels() {
    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.domElement.style.position = 'absolute';
    this.labelRenderer.domElement.style.top = '0';
    this.labelRenderer.setSize(this.sizes.width, this.sizes.height);
    document.body.appendChild(this.labelRenderer.domElement);

    // Labels
    const label1 = document.querySelector('.labelApropos');
    const label2 = document.querySelector('.labelProjets');
    const label3 = document.querySelector('.labelContact');

    const labelApropos = new CSS2DObject(label1);
    labelApropos.position.set(-2.7, 1, 2.7);

    const labelProjets = new CSS2DObject(label2);
    labelProjets.position.set(2.53, 0.4, 2.53);

    const labelContact = new CSS2DObject(label3);
    labelContact.position.set(-1.17, 3.65, -1.8);

    this.scene.add(labelApropos, labelProjets, labelContact);

    // Store the CSS2DObjects in this.labels array
    this.labels = [label1, label2, label3];
    this.labelsRaycast = [labelApropos, labelProjets, labelContact];
  }

  // ===== Raycaster =====
  checkLabelVisibility() {
    this.labelsRaycast.forEach((label) => {
      const raycaster = new THREE.Raycaster();
      raycaster.set(
        label.position,
        this.camera.position.clone().sub(label.position).normalize()
      );

      const intersects = raycaster.intersectObjects(this.scene.children, true);

      const isBehind =
        intersects.length > 0 &&
        intersects[0].distance <
          label.position.distanceTo(this.camera.position);

      if (isBehind) {
        label.element.style.opacity = '0';
        label.element.style.pointerEvents = 'none';
      } else {
        label.element.style.opacity = '1';
        label.element.style.pointerEvents = 'auto';
      }
    });
  }

  // ===== Renderer =====
  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true,
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.render(this.scene, this.camera);
  }

  // ===== Orbit Controls =====
  createOrbitControls() {
    this.controls = new OrbitControls(
      this.camera,
      this.labelRenderer.domElement
    );
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.075;

    this.controls.enablePan = false;

    // Set limits
    this.controls.maxPolarAngle = Math.PI / 1.95;
    this.controls.minPolarAngle = 0.5;

    this.controls.minDistance = 5;
    this.controls.maxDistance = 15;

    this.controls.target.set(0, 1.5, 0);
    this.controls.update();
  }

  // ===== Animate =====
  animate() {
    const elapsedTime = this.clock.getElapsedTime();
    // const deltaTime = this.clock.getDelta();

    if (this.mixer) {
      this.mixer.update(elapsedTime);
    }

    this.controls.update();

    this.renderer.render(this.scene, this.camera);
    this.labelRenderer.render(this.scene, this.camera);

    this.checkLabelVisibility();

    window.requestAnimationFrame(this.animate.bind(this));
  }

  // ===== Resize =====
  resize() {
    // Update sizes based on the window
    this.sizes.width = window.innerWidth;
    this.sizes.height = window.innerHeight;

    // Update camera aspect ratios
    this.camera.aspect = this.sizes.width / this.sizes.height;
    this.camera.updateProjectionMatrix();

    // Update the main renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.render(this.scene, this.camera);

    // Update label renderer
    this.labelRenderer.setSize(this.sizes.width, this.sizes.height);
    this.labelRenderer.render(this.scene, this.camera);
  }

  // ===== Clicks =====
  handleClicks() {
    for (let i = 0; i < this.labels.length; i++) {
      this.labels[i].addEventListener('mousedown', this.onMouseDown.bind(this));
    }

    const projets = document.querySelectorAll('.projet__list');
    projets.forEach((projet) => {
      projet.addEventListener('click', this.onMouseDown.bind(this));
    });

    const closeButtons = document.querySelectorAll('.btn_close');
    closeButtons.forEach((button) => {
      button.addEventListener('click', this.onMouseClick.bind(this));
    });

    const idProjets = document.querySelectorAll('.projets_js');
    idProjets.forEach((button) => {
      button.addEventListener('click', this.onMouseClick.bind(this));
    });
  }

  onMouseDown(event) {
    const evt = event.currentTarget;
    this.pages = [
      this.pageApropos,
      this.pageProjets,
      this.pageContact,
      this.pageThousandSunny,
    ];

    this.pageToShow;

    switch (true) {
      case evt.classList.contains('labelApropos'):
        this.pageToShow = this.pageApropos;
        break;
      case evt.classList.contains('labelProjets'):
        this.pageToShow = this.pageProjets;
        break;
      case evt.classList.contains('labelContact'):
        this.pageToShow = this.pageContact;
        break;
      case evt.dataset.title == 'Thousand Sunny':
        this.pageToShow = this.pageThousandSunny;
        break;
    }

    const isActive =
      this.pageToShow && !this.pageToShow.classList.contains('hide_js');

    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i].classList.add('hide_js');
    }

    if (!isActive && this.pageToShow) {
      this.pageToShow.classList.remove('hide_js');
    }
  }

  onMouseClick(event) {
    const evt = event.currentTarget;
    const isProjetBtn = evt.closest('.projet') !== null;
    const isProjetsLink = evt.closest('a[href="#projets"]') !== null;

    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i].classList.add('hide_js');
    }

    if (isProjetBtn || isProjetsLink) {
      this.pageProjets.classList.remove('hide_js');
    }
  }

  // ===== Observe =====
  handleObserve() {
    window.addEventListener('resize', this.resize.bind(this));
    const observer = new IntersectionObserver(this.observe.bind(this), {
      rootMargin: '-45% 0px',
    });

    const scrolls = document.querySelectorAll('.panel__content > section');
    for (let i = 0; i < scrolls.length; i++) {
      const element = scrolls[i];
      observer.observe(element);
    }
  }

  observe(entries) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const target = entry.target;

      // if (entry.isIntersecting && this.model) {
      //   gsap.to(this.model.position, {
      //     duration: 0.5,
      //     ease: 'power2.inOut',
      //     x: target.dataset.p,
      //   });

      //   gsap.to(this.model.rotation, {
      //     duration: 0.5,
      //     ease: 'power2.inOut',
      //     x: target.dataset.rX,
      //     y: target.dataset.rY,
      //     z: target.dataset.rZ,
      //   });

      //   const cameraZ = 'cZ' in target.dataset ? target.dataset.cZ : 8;
      //   gsap.to(this.camera.position, {
      //     duration: 0.5,
      //     ease: 'power2.inOut',
      //     z: cameraZ,
      //   });
      // }
    }
  }

  startAnimations() {
    gsap.to(this.camera.position, {
      duration: 2,
      ease: 'power2.inOut',
      x: 6,
      y: 3,
      z: 10,
    });
  }
}
